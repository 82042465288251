import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { Box, Button, Typography } from '@mui/material';
import queryString from 'query-string';
import React, { useMemo, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { useLocation, useParams } from 'react-router-dom';

import { getItemData } from '../data';

// const bookLink = {
//   url: "https://bootorab.s3.ir-thr-at1.arvanstorage.ir/komail.pdf",
// };

// eslint-disable-next-line no-unused-vars
const { innerWidth: width, innerHeight: height } = window;

export const PdfViewer = () => {
  const location = useLocation();
  const { id } = useParams();
  const numPages = useRef(0);
  const [pageNumber, setPageNumber] = useState<number>(
    Number(queryString.parse(location.search)?.['page']) || 1
  );
  const [, setLoading] = useState(true);
  const [pageWidth, setPageWidth] = useState(0);
  const [pageHeight, setPageHeight] = useState(0);

  const file = useMemo(
    () => ({
      url: `https://bootorab.s3.eu-north-1.amazonaws.com/${id}.pdf`,
    }),
    [id]
  );

  const options = useMemo(
    () => ({
      cMapUrl: 'cmaps/',
      cMapPacked: true,
      standardFontDataUrl: 'standard_fonts/',
    }),
    []
  );

  function onDocumentLoadSuccess({ numPages: nextNumPages }: { numPages: number }) {
    numPages.current = nextNumPages;
  }

  function onPageLoadSuccess() {
    setPageHeight(window.innerHeight);
    setPageWidth(window.innerWidth);
    setLoading(false);
  }

  function goToNextPage() {
    if (pageNumber < numPages.current) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  }

  function goToPreviousPage() {
    if (pageNumber > 1) {
      setPageNumber((prevPageNumber) => prevPageNumber - 1);
    }
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          backgroundColor: 'black',
          height: '45px',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '10px',
        }}
      >
        <Typography variant="h5" color="red">
          {getItemData(id).title}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          options={options}
          renderMode="canvas"
          className=""
        >
          <Page
            className=""
            key={pageNumber}
            pageNumber={pageNumber}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            onLoadSuccess={onPageLoadSuccess}
            onRenderError={() => setLoading(false)}
            height={pageWidth > 600 ? Math.max(pageHeight * 0.9, 10) : undefined}
            width={pageWidth < 600 ? Math.min(pageWidth * 0.9, 600) : undefined}
          />
        </Document>
      </Box>
      <Box
        sx={{
          display: 'flex',
          position: 'absolute',
          bottom: '10px',
          backgroundColor: 'black',
          height: '45px',
          width: width - 25,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Button onClick={goToPreviousPage}>
          <ChevronLeftIcon color="red" height={40} />
        </Button>
        <Typography color="red">{`${pageNumber}/${numPages.current}`}</Typography>
        <Button onClick={goToNextPage}>
          <ChevronRightIcon color="red" height={40} />
        </Button>
      </Box>
    </Box>
  );
};

export default PdfViewer;
